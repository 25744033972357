const frontend = [
  "frontend.png",
  "frontend_url.png"
]

const backend = [
  "light_index.png",
  "dark_index.png",
  "light_stats.png"
]

state = "frontend";

$( document ).ready(function() {

  $('[data-toggle="tooltip"]').tooltip();
  
  $("#frontend").click(function () {
    $("#frontend").addClass("selected");
    $("#backend").removeClass("selected");

    if (state != "frontend") {
      switcher(frontend); 
      state = "frontend";
    }
  })

  $("#backend").click(function () {
    $("#frontend").removeClass("selected");
    $("#backend").addClass("selected");

    if (state != "backend") {
      switcher(backend);
      state = "backend";
    }
  })

  function switcher(data) {
    $("#carouselIndicators").fadeOut(function () {
      $("#indicators").empty();

      $("#slider_content").empty();
      for (let i = 0; i < data.length; i++) {
        if (i == 0) {
          $("#slider_content").append(`<div class="carousel-item active"><img class="d-block" src="assets/img/slides/${data[i]}"></div>`);
          $("#indicators").append(`<li data-target="#carouselIndicators" data-slide-to="${i}" class="active"></li>`);
        } else {
          $("#slider_content").append(`<div class="carousel-item"><img class="d-block" src="assets/img/slides/${data[i]}"></div>`);
          $("#indicators").append(`<li data-target="#carouselIndicators" data-slide-to="${i}" class=""></li>`);
        }
      }
    });

    $("#carouselIndicators").fadeIn();
  }
});
